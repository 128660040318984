<template>
  <v-row class="login">
    <v-col cols="8" class="left">
      <h2>E-WAY</h2>
    </v-col>
    <v-col cols="4" class="right">
      <v-card width="500" color="#da241b" elevation="0">
        <h2 class="text-center">Вход</h2>
        <v-form v-model="valid" ref="form" @submit.prevent="login">
          <v-text-field
            v-model="username"
            :rules="rules"
            autocomplete="username"
            solo
            dense
            placeholder="Email"
            class="pt-4 my-n2"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :rules="rules"
            type="password"
            autocomplete="current-password"
            :error-messages="error_message"
            solo
            dense
            placeholder="Пароль"
            class="my-n2"
          ></v-text-field>
          <v-btn type="submit" v-show="false"></v-btn>
        </v-form>
        <v-btn block @click="login" class="mt-3">Вход</v-btn>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      valid: true,
      username: "",
      password: "",
      error_message: "",
      rules: [
        (value) => !!value || this.$i18n.t("rulesText.required"),
        (value) => value.indexOf(" ") < 0 || this.$i18n.t("rulesText.noSpace"),
        // value => value.length > 15 || this.$i18n.t("rulesText.min15"),
      ],
    };
  },

  computed: {},
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.$auth
          .login({
            data: {
              email: this.username,
              password: this.password,
            },
          })
          .then(() => {
            this.$router.push("/dashboard");
          })
          .catch(() => {
            this.error_message = "Неправильный логин или пароль!";
            this.valid = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.login {
  padding: 0;
  max-width: 1000px;
  margin: 0 auto;
  min-height: 100vh;
  box-shadow: 0 0 1px 1px rgba($color: #000000, $alpha: 0.1);
  .left {
    padding: 30px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    color: #f8241b;
    background-color: #f9f9f9;
  }
  .right {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
    background: #da241b;
    color: #fff;
    h2 {
      text-align: center;
      margin: 30px 0;
    }
    .signin-btn {
      //width: 100%;
      color: #da241b;
    }
  }
}
</style>
